import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

import AboutBox from "../components/AboutBox";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import WhatsappButton from "../components/WhatsappButton";
import "../scss/main.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

/* import heroImg from "../images/about_header.jpg"; */
import icon_linkedin from "../images/logo-linkedin.svg";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulTeamMember(sort: { fields: order_number, order: ASC }) {
        nodes {
          name
          position_es
          linkedin_profile_link
          photo {
            file {
              url
            }
          }
        }
      }
      allContentfulAboutPageTemplate {
        nodes {
          hero_title_es
          hero_description_es {
            hero_description_es
          }
          imageHero {
            file {
              url
            }
          }
        }
      }
      allContentfulAboutUsBox {
        nodes {
          image {
            file {
              url
            }
          }
          title_es
          description_es {
            raw
          }
        }
      }
    }
  `);

  const pageTitle = "About | Virtual Ed Global"
  const heroTitle = data.allContentfulAboutPageTemplate.nodes[0].hero_title_es;
  const heroDescription =
    data.allContentfulAboutPageTemplate.nodes[0].hero_description_es.hero_description_es !== "empty"
      ? data.allContentfulAboutPageTemplate.nodes[0].hero_description_es.hero_description_es
      : "";
  const heroImg = data.allContentfulAboutPageTemplate.nodes[0].imageHero.file.url;

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={heroTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/about" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImg} />
        <meta name="twitter:image" content={heroImg} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} /> 
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <HeroSection image={heroImg} title={heroTitle} description={heroDescription} />
        <section>
          <div className="container">
            {data.allContentfulAboutUsBox.nodes.map((item, index) => (
              <AboutBox
                title={item.title_es}
                description={item.description_es}
                image={item.image.file.url}
                index={index}
                key={item.title_es}
              />
            ))}
          </div>
        </section>

        <section className="equipo">
          <div className="container">
            <h2 className="title">Equipo</h2>
            <div className="row">
              {data.allContentfulTeamMember.nodes.map((item, index) => (
                <div className="col-md-3 text-center" key={index}>
                  <img src={item.photo.file.url} alt="" className="rounded-circle avatar" width="120" />
                  <h4>{item.name}</h4>
                  <p className="cargo">{item.position_es}</p>
                  <p>
                    <a href={item.linkedin_profile_link} className="social_link" target="_blank" rel="noreferrer">
                      <img src={icon_linkedin} alt="" width="30" />
                    </a>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="contacto">
          <Contact
            title={
              <p>
                <strong>Aquí estamos para conversar sobre Educación Superior.</strong> Esperamos tus consultas o
                comentarios.
              </p>
            }
            button_label="Conversemos"
          />
        </section>
      </div>

      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default AboutPage;
